import React from 'react';
import { motion } from 'framer-motion';

const FramerButton = ({ onClick, className, children }) => {
    return (
    <motion.button
      className={`${className}`}
      onClick={onClick}
      whileTap={{ scale: 0.95 }}
      whileHover={{ scale: 1.05 }}
      initial={{ opacity: 0, y: -10 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -10 }}
    >
      {children}
    </motion.button>
  );
};

export default FramerButton;