import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faPlus, faX, faEdit } from '@fortawesome/free-solid-svg-icons';
import { useRoute } from 'wouter';
import alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.min.css';
import '../styles/LoteDetails.css';
import FramerButton from './FramerButton';
import BackButton from './BackButton';
import AgregarIncidenciaForm from './AgregarIncidenciaForm';

alertify.set('notifier', 'position', 'top-right');

const LoteDetails = () => {
  const [lote, setLote] = useState(null);
  const [historialIncidencias, setHistorialIncidencias] = useState([]);
  const [, params] = useRoute("/:constructoraId/fraccionamientos/:fraccionamientoId/:loteId");
  const [selectedEstado, setSelectedEstado] = useState('');
  const [historialCargado, setHistorialCargado] = useState(false);
  const [showAgregarIncidenciaModal, setShowAgregarIncidenciaModal] = useState(false);
  const [showImagenModal, setShowImagenModal] = useState(false);
  const [imagenModalUrl, setImagenModalUrl] = useState('');

  const estadoColores = {
    Listo: 'green',
    proceso: 'blue',
    Incidencia: 'red',
  };

  useEffect(() => {
    if (!params.loteId) return;

    fetch(`https://back.ivdian.ca/api/constructoras/${params.constructoraId}/fraccionamientos/${params.fraccionamientoId}/lotes/${params.loteId}`)
      .then((response) => response.json())
      .then((data) => {
        setLote(data);
        setSelectedEstado(data.estado);
      });

    if (!historialCargado) {
      fetch(`https://back.ivdian.ca/api/constructoras/${params.constructoraId}/fraccionamientos/${params.fraccionamientoId}/lotes/${params.loteId}/incidencias`)
        .then((response) => response.json())
        .then((data) => {
          setHistorialIncidencias(data);
          setHistorialCargado(true);
        })
        .catch((error) => alertify.error('Error al obtener el historial de incidencias', error));
    }
  }, [params, historialCargado]);

  const handleEditarLote = () => {
    const camposEditables = ['openings'];
    editarCamposRecursivamente(camposEditables, 0);
  };

  const editarCamposRecursivamente = async (campos, index) => {
    if (index >= campos.length) {
      return;
    }

    const campo = campos[index];

    if (campo === 'openings') {
      const propiedadesOpenings = ['frente', 'derecha', 'izquierda', 'atras'];
      await editarPropiedadesOpenings(propiedadesOpenings, 0);
    } else {
      await alertify.prompt(`Editar ${campo}:`, lote[campo], async (e, nuevoValor) => {
        if (e) {
          const valorNumerico = parseFloat(nuevoValor);
          if (!isNaN(valorNumerico)) {
            const valorFormateado = valorNumerico.toFixed(2);
            await mostrarModalEditarLote({ [campo]: valorFormateado });
          }
        }

        editarCamposRecursivamente(campos, index + 1);
      });
    }
  };

  const editarPropiedadesOpenings = async (propiedades, index) => {
    if (index >= propiedades.length) {
      editarCamposRecursivamente(['vinil', 'basement', 'color'], 0);
      return;
    }

    const propiedad = propiedades[index];

    await alertify.prompt(`Editar ${propiedad}:`, lote.openings[propiedad], async (e, nuevoValor) => {
      if (e) {
        const valorNumerico = parseFloat(nuevoValor);
        if (!isNaN(valorNumerico)) {
          const valorFormateado = valorNumerico.toFixed(2);
          await mostrarModalEditarLote({ openings: { [propiedad]: valorFormateado } });
        }
      }

      editarPropiedadesOpenings(propiedades, index + 1);
    });
  };

  const mostrarModalEditarLote = async (camposEditados) => {
    try {
      const response = await fetch(`https://back.ivdian.ca/api/constructoras/${params.constructoraId}/fraccionamientos/${params.fraccionamientoId}/lotes/${params.loteId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          loteId: lote.id,
          camposEditados,
        }),
      });

      if (response.ok) {
        setLote((prevLote) => ({ ...prevLote, ...camposEditados }));
        alertify.success('Lote editado exitosamente');
      } else {
        alertify.error('Error al editar el lote');
      }
    } catch (error) {
      alertify.error('Error al editar el lote no accedio', error);
    }
  };

  const formatFecha = (fechaString) => {
    const fecha = new Date(fechaString);
    return `${fecha.toLocaleDateString()} ${fecha.toLocaleTimeString()}`;
  };

  const iconColor = estadoColores[selectedEstado];

  return (
    <div className="container mt-4 page">
      {lote ? (
        <div className="bg-white shadow-md rounded-lg overflow-hidden">
          <div className="bg-gray-400 border-b border-gray-300 py-4 px-6">
          <BackButton destination={`/${params.constructoraId}/fraccionamientos/${params.fraccionamientoId}`} />

            <div className="flex justify-between items-center">
              <h2 className="text-xl font-semibold text-white">Detalles del Lote</h2>
              <FramerButton
                className="bg-yellow-500 shadow-md shadow-yellow-600 hover:shadow-yellow-600/30 text-white hover:bg-yellow-600 py-2 px-4 rounded-lg inline-flex items-center"
                onClick={() => setShowAgregarIncidenciaModal(true)}
              >
                <FontAwesomeIcon icon={faPlus} className="mr-2" />
                Add
              </FramerButton>
              <FontAwesomeIcon icon={faHome} size="lg" className={`text-${iconColor}-500`} />

              <FramerButton
                className="bg-yellow-500 shadow-md shadow-yellow-600 hover:shadow-yellow-600/30 text-white hover:bg-yellow-600 py-2 px-4 rounded-lg inline-flex items-center"
                onClick={() => handleEditarLote(true)}
              >
                <FontAwesomeIcon icon={faEdit} className="mr-2" />
                Editar
              </FramerButton>
            </div>
          </div>
          <div className="p-6">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <div>
                <p className="text-gray-600 font-medium">Número de Lote</p>
                <p>{lote.numero_lote}</p>
              </div>
              <div>
                <p className="text-gray-600 font-medium">Openings</p>
                {typeof lote.openings === 'object' ? (
                  <div>
                    <p>Frente: {lote.openings.frente || '0.00'}</p>
                    <p>Derecha: {lote.openings.derecha || '0.00'}</p>
                    <p>Izquierda: {lote.openings.izquierda || '0.00'}</p>
                    <p>Atrás: {lote.openings.atras || '0.00'}</p>
                    <p>Total: {
                      (parseFloat(lote.openings.frente) || 0) +
                      (parseFloat(lote.openings.derecha) || 0) +
                      (parseFloat(lote.openings.izquierda) || 0) +
                      (parseFloat(lote.openings.atras) || 0)
                    }</p>
                  </div>
                ) : (
                  <p>{lote.openings}</p>
                )}
              </div>
              <div>
                <p className="text-gray-600 font-medium">Vinil</p>
                <p>{lote.vinil}</p>
              </div>
              <div>
                <p className="text-gray-600 font-medium">Basement</p>
                <p>{lote.basement}</p>
              </div>
              <div>
                <p className="text-gray-600 font-medium">Color</p>
                <p>{lote.color}</p>
              </div>
              <div>
                <p className="text-gray-600 font-medium">Estatus</p>
                <p className={`text-${iconColor}-500 font-bold text-xl`}>{selectedEstado}</p>
              </div>
            </div>
            {historialIncidencias.length > 0 && (
                <div className="mt-6">
                  <h3 className="text-gray-600 font-medium text-lg">Historial de Incidencias</h3>
                  <ul className="mt-4 space-y-2">
                    {historialIncidencias.map((incidencia) => (
                      <li key={incidencia._id} className="flex items-center space-x-4">
                        <div
                          className={`w-10 h-10 bg-${iconColor}-100 rounded-full flex items-center justify-center`}
                        >
                          <FontAwesomeIcon icon={faEdit} className={`text-${iconColor}-500`} />
                 
                        </div>
                        <div>
                          <p className="text-gray-600">Titulo: {incidencia.titulo}</p>
                          <p className="text-gray-600">Descripcion: {incidencia.descripcion}</p>
                          <p className="text-gray-600">Fecha: {formatFecha(incidencia.fecha)}</p>
                          {incidencia.imagen && (
                            <div className="mt-2">
                              <button
                                className={`text-${iconColor}-500 hover:underline`}
                                onClick={() => {
                                  const imageUrl = `https://back.ivdian.ca/${incidencia.imagen.replace('incidencias\\', '')}`;
                                  setImagenModalUrl(imageUrl);
                                  setShowImagenModal(true);
                                }}
                              >
                                Ver Imagen
                              </button>
                            </div>
                          )}
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
          )}
          </div>
        </div>
      ) : (
        <p>Cargando detalles del lote...</p>
      )}
      {/* Modal para agregar incidencia */}
      {showAgregarIncidenciaModal && (
          <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="bg-white w-96 rounded-lg overflow-hidden">          
              <div className="bg-gray-400 py-4 px-6 relative">
                <h2 className="inline-block text-xl font-semibold text-white">Agregar Incidencia</h2>
                <FramerButton
                  className="bg-red-500 hover:bg-red-800 text-white ml-2 px-4 py-2 absolute right-5 rounded-md"
                  onClick={() => {
                    setShowAgregarIncidenciaModal(false);
                    setHistorialCargado(false); // Forzar la recarga del historial
                  }}
                >
                  <FontAwesomeIcon icon={faX} className={`text-white-500`} />
                </FramerButton>
              </div>
              <div className="p-6">
                <AgregarIncidenciaForm
                  constructoraId={params.constructoraId}
                  fraccionamientoId={params.fraccionamientoId}
                  loteId={params.loteId}
                  onIncidenciaAgregada={() => {
                    setShowAgregarIncidenciaModal(false);
                    setHistorialCargado(false); // Forzar la recarga del historial
                  }}
                />
                <div className="flex justify-end">
                </div>
              </div>
            </div>
          </div>
        )}
      {/* Modal para ver imagen de incidencia */}
      {showImagenModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="bg-white w-96 rounded-lg overflow-hidden">
            <div className="bg-gray-400 py-4 px-6">
              <h2 className="text-xl font-semibold text-white">Imagen de Incidencia</h2>
            </div>
            <div className="p-6">
              <img src={imagenModalUrl} alt="Imagen de Incidencia" className="w-full" />
              <div className="flex justify-end mt-4">
                <FramerButton
                  className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md"
                  onClick={() => setShowImagenModal(false)}
                >
                  Cerrar
                </FramerButton>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LoteDetails;