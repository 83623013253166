// TransitionContainer.js
import React from 'react';
import { motion } from 'framer-motion';

const pageTransitionVariants = {
  initial: {
    opacity: 0,
    scale: 0.98,
  },
  animate: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.4, // Aumenta la duración de la transición para que sea más lenta
      ease: "easeOut", // Utiliza una función de tiempo "easeOut" para suavizar la transición
    },
  },
  exit: {
    opacity: 0,
    scale: 0.98,
    transition: {
      duration: 0.4,
      ease: "easeIn", // Utiliza una función de tiempo "easeIn" para suavizar la salida
    },
  },
};

const TransitionContainer = ({ children }) => {
  return (
    <motion.div
      variants={pageTransitionVariants}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      {children}
    </motion.div>
  );
};

export default TransitionContainer;
