// Sound.js
import React, { createContext, useContext, useState } from 'react';
import { MembraneSynth } from 'tone';

const SoundContext = createContext();

export const useSound = () => {
  return useContext(SoundContext);
};

export const SoundProvider = ({ children }) => {
  const [, setIsMouseDown] = useState(false);

  const synth = new MembraneSynth({
    pitchDecay: 0.01,
    octaves: 21,
    oscillator: {
      type: 'sine',
    },
  }).toDestination();

  const playSuccessSound = () => {
    const note = 'C8';
    const duration = '16n';
    synth.triggerAttackRelease(note, duration);
  };

  const playErrorSound = () => {
    const note = 'A4'; // Cambia la nota o ajusta según tus preferencias
    const duration = '16n';
    synth.triggerAttackRelease(note, duration);
  };

  const handleMouseDown = () => {
    setIsMouseDown(true);
    playSuccessSound(); // Puedes usar playSuccessSound o playErrorSound según necesites
  };

  const handleMouseUp = () => {
    setIsMouseDown(false);
    playSuccessSound(); // Puedes usar playSuccessSound o playErrorSound según necesites
  };

  return (
    <SoundContext.Provider value={{ handleMouseDown, handleMouseUp, playSuccessSound, playErrorSound }}>
      {children}
    </SoundContext.Provider>
  );
};