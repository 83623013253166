import React, { useState } from 'react';
import axios from 'axios';

const AgregarIncidenciaForm = ({ constructoraId, fraccionamientoId, loteId, onIncidenciaAgregada  }) => {
  const [titulo, setTitulo] = useState('');
  const [descripcion, setDescripcion] = useState('');
  const [imagen, setImagen] = useState(null);
  const [imagenPreview, setImagenPreview] = useState(null);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImagen(file);
    setImagenPreview(URL.createObjectURL(file));

  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('titulo', titulo);
    formData.append('descripcion', descripcion);
    formData.append('loteId', loteId);

    if (imagen) {
      formData.append('imagen', imagen);
    }

    try {
      const response = await axios.post(
        `https://back.ivdian.ca//api/constructoras/${constructoraId}/fraccionamientos/${fraccionamientoId}/lotes/${loteId}/incidencias`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (response.status === 200) {
        console.log('Incidencia agregada exitosamente');
        onIncidenciaAgregada(); // Llamada a la función proporcionada como prop
        // Puedes realizar alguna acción adicional después de agregar la incidencia
      } else {
        console.error('Error al agregar la incidencia');
      }
    } catch (error) {
      console.error('Error al enviar los datos de la incidencia', error);
    }
  };

  return (
    <div className="p-6 bg-white rounded-lg shadow-md">
    <h3 className="text-lg font-semibold mb-4">Agregar Nueva Incidencia</h3>
    <form onSubmit={handleSubmit} encType="multipart/form-data">
      <div className="mb-4">
        <label htmlFor="titulo" className="block text-sm font-medium text-gray-700">
          Título de la Incidencia:
        </label>
        <input
          type="text"
          id="titulo"
          value={titulo}
          onChange={(e) => setTitulo(e.target.value)}
          className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500"
          required
        />
      </div>

      <div className="mb-4">
        <label htmlFor="descripcion" className="block text-sm font-medium text-gray-700">
          Descripción de la Incidencia:
        </label>
        <textarea
          id="descripcion"
          value={descripcion}
          onChange={(e) => setDescripcion(e.target.value)}
          className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500"
          required
        ></textarea>
      </div>

      <div className="mb-4">
          <label htmlFor="imagen" className="block text-sm font-medium text-gray-700">
            Imagen de la Incidencia:
          </label>
          <div className="flex items-center mt-1">
            {imagenPreview && (
              <img src={imagenPreview} alt="Vista previa de la imagen" className="w-20 h-20 mr-2 rounded-md" />
            )}
            <label
              htmlFor="imagen"
              className="bg-blue-500 text-white p-2 rounded-md cursor-pointer hover:bg-blue-600 focus:outline-none focus:ring focus:border-blue-300"
            >
              Agregar
            </label>
            <span className="ml-2">{imagen ? imagen.name : 'Selecciona una imagen'}</span>
            <input
              type="file"
              id="imagen"
              name="imagen"
              accept="image/*"
              onChange={handleImageChange}
              className="hidden"
            />
          </div>
        </div>

      <button
        type="submit"
        className="bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:border-blue-300"
      >
        Agregar Incidencia
      </button>
    </form>
  </div>
);
};

export default AgregarIncidenciaForm;
