import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'wouter';
import FramerButton from './FramerButton';

const BackButton = ({ destination }) => {
  const [, setLocation] = useLocation();

  const goBack = () => {
    setLocation(destination);
  };

  return (
    <FramerButton
      className="text-white btn btn-dark"
      onClick={goBack}
    >
      Regresar
    </FramerButton>
  );
};

BackButton.propTypes = {
  destination: PropTypes.string.isRequired,
};

export default BackButton;