import React from 'react';
import { createRoot } from 'react-dom/client';
import { Router } from 'wouter';
import App from './App';


import 'alertifyjs/build/css/alertify.css';
import 'alertifyjs/build/css/themes/default.css';
import './index.css'

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';



// Usa createRoot desde react-dom/client
const root = createRoot(document.getElementById('root'));

// Envuelve la aplicación en el componente Router de wouter
root.render(
  <Router>
      <App />

  </Router>
);