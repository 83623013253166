import React, { useState, useEffect } from 'react';
import { Link } from 'wouter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import FramerButton from './FramerButton';
import { motion, AnimatePresence } from 'framer-motion';

// Importa el componente de Modal aquí
import Modal from './Modal';

const FraccionamientosList = () => {
  const [constructoras, setConstructoras] = useState([]);
  const [fraccionamientos, setFraccionamientos] = useState([]);
  const [selectedConstructora, setSelectedConstructora] = useState(null);
  const [password, setPassword] = useState('');
  const [isPasswordCorrect, setIsPasswordCorrect] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false); // Estado para controlar la apertura del modal
  const [nombreFraccionamiento, setNombreFraccionamiento] = useState('');

  useEffect(() => {
    // Cargar la lista de constructoras al montar el componente
    fetch('https://back.ivdian.ca/api/constructoras')
      .then((response) => response.json())
      .then((data) => setConstructoras(data))
      .catch((error) => console.error('Error al obtener la lista de constructoras', error));
  }, []);

  useEffect(() => {
    // Cargar la lista de fraccionamientos asociados a la constructora seleccionada
    if (selectedConstructora) {
      fetch(`https://back.ivdian.ca/api/constructoras/${selectedConstructora._id}/fraccionamientos`)
        .then((response) => response.json())
        .then((data) => {
          const fraccionamientosFiltrados = data.filter(fraccionamiento => fraccionamiento.constructora === selectedConstructora._id);
          setFraccionamientos(fraccionamientosFiltrados);
        })
        .catch((error) => console.error('Error al obtener la lista de fraccionamientos', error));
    } else {
      setFraccionamientos([]);
    }
  }, [selectedConstructora]);

  useEffect(() => {
    // Verificar si ya hay una contraseña almacenada para la constructora seleccionada
    if (selectedConstructora) {
      const savedPassword = localStorage.getItem(`password_${selectedConstructora._id}`);
      if (savedPassword) {
        setIsPasswordCorrect(true);
      }
    }
  }, [selectedConstructora]);

  const handleConstructoraSelection = (constructora) => {
    setSelectedConstructora(constructora);
  };

  const handlePasswordValidation = () => {
    const selectedConstructoraPassword = selectedConstructora.contraseña;

    if (password === selectedConstructoraPassword) {
      setIsPasswordCorrect(true);
      localStorage.setItem(`password_${selectedConstructora._id}`, password);
    } else {
      setIsPasswordCorrect(false);
      alert('Contraseña incorrecta. Por favor, inténtalo de nuevo.');
    }
  };

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleCrearFraccionamiento = () => {
    // Aquí puedes implementar la lógica para enviar la solicitud al backend y crear el fraccionamiento
    // Asegúrate de incluir el ID de la constructora seleccionada en la solicitud
    // Después de crear el fraccionamiento, puedes recargar la lista de fraccionamientos
    // Por ejemplo:
    /*
    fetch(`URL_DEL_BACKEND/api/fraccionamientos`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        nombre: nombreFraccionamiento,
        constructora: selectedConstructora._id,
      }),
    })
      .then(response => response.json())
      .then(data => {
        console.log('Fraccionamiento creado:', data);
        setFraccionamientos([...fraccionamientos, data]);
      })
      .catch(error => console.error('Error al crear el fraccionamiento', error));
    */
    setIsModalOpen(false);
  };

  return (
    <div className="container fos mx-auto mt-5">
      <h2 className="text-center mb-4 text-2xl">
        {selectedConstructora ? `Fraccionamientos de ${selectedConstructora.nombre}` : 'Lista de Constructoras'}
      </h2>
      {!selectedConstructora && (
        <AnimatePresence>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            key="constructoras"
            className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4"
          >
            {constructoras.map((constructora) => (
              <div key={constructora._id} className="border sms rounded-lg shadow-xl">
                <div className="p-4 text-center">
                  <h5 className="mt-2">{constructora.nombre}</h5>
                  <FramerButton onClick={() => handleConstructoraSelection(constructora)}>
                    Seleccionar Constructora
                  </FramerButton>
                </div>
              </div>
            ))}
          </motion.div>
        </AnimatePresence>
      )}
      {selectedConstructora && !isPasswordCorrect && (
        <div className="text-center mt-8">
          <h3 className="text-xl font-semibold mb-4">Ingrese la contraseña para {selectedConstructora.nombre}</h3>
          <div className="mb-4">
            <input
              type="password"
              className="form-input w-full p-2 rounded-lg border focus:outline-none focus:ring-2 focus:ring-blue-400"
              placeholder="Contraseña"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <FramerButton onClick={handlePasswordValidation} className="bg-blue-500 hover:bg-blue-600 text-white">
            Ingresar
          </FramerButton>
        </div>
      )}
      {selectedConstructora && isPasswordCorrect && (
        <div>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            key="fraccionamientos"
            className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4"
          >
            {Array.isArray(fraccionamientos) && fraccionamientos.length > 0 ? (
              fraccionamientos.map((fraccionamiento) => {
                let colorClass = 'text-blue-600';
                return (
                  <div key={fraccionamiento._id} className="border sms rounded-lg shadow-xl">
                    <div className="p-4 text-center">
                      <FontAwesomeIcon icon={faHome} className={`text-3xl ${colorClass}`} />
                      <h5 className="mt-2">{fraccionamiento.nombre}</h5>
                      <FramerButton>
                        <Link
                          to={`/${selectedConstructora._id}/fraccionamientos/${fraccionamiento._id}`}
                          className="btn btn-dark"
                        >
                          Entrar
                        </Link>
                      </FramerButton>
                    </div>
                  </div>
                );
              })
            ) : (
              <p>No hay fraccionamientos disponibles.</p>
            )}
          </motion.div>

          {/* Botón para abrir el modal */}
          <FramerButton onClick={handleModalOpen} className="bg-green-500 m-5 p-2 rounded hover:bg-green-600 text-white">
            Crear Fraccionamiento
          </FramerButton>

          {/* Modal para crear un fraccionamiento */}
          <Modal isOpen={isModalOpen} onClose={handleModalClose}>
            <h2 className="text-xl font-semibold mb-4">Crear Fraccionamiento</h2>
            <div className="mb-4">
              <input
                type="text"
                className="form-input w-full p-2 rounded-lg border focus:outline-none focus:ring-2 focus:ring-blue-400"
                placeholder="Nombre del Fraccionamiento"
                value={nombreFraccionamiento}
                onChange={(e) => setNombreFraccionamiento(e.target.value)}
              />
              <div className="relative pt-4 pb-3">
                <FramerButton onClick={handleCrearFraccionamiento} className="bg-blue-500 p-2 absolute left-0 rounded hover:bg-blue-600 text-white">
                  agregar
                </FramerButton>
                <FramerButton onClick={handleModalClose} className="bg-red-500 p-2 rounded absolute right-0 hover:bg-red-600 text-white">
                  cancelar
                </FramerButton>
              </div>
            </div>
           
          </Modal>
        </div>
      )}
    </div>
  );
};

export default FraccionamientosList;
