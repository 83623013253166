import React from 'react'

export const Footer = () => {
  return (
    <div className="container">
  <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
    <div className="col-md-4 d-flex align-items-center">
      <span className="mb-3 mb-md-0 text-body-secondary">&copy; 2023 RedStar Caulking</span>
    </div>
  </footer>
</div>
  )
}
