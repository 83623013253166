import React, { useState } from 'react';
import axios from 'axios';


const AgregarLoteComponent = ({ fraccionamientoId, onAddLote }) => {
  const [formData, setFormData] = useState({
    numero_lote: '',
    openings: {
      frente: '',
      derecha: '',
      izquierda: '',
      atras: '',
    },
    vinil: '',
    basements: '',
    color: '',
    estado: '',
    fraccionamiento: fraccionamientoId,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleOpeningsChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      openings: {
        ...formData.openings,
        [name]: value,
      },
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`https://back.ivdian.ca/api/constructoras/${formData.constructoraId}/fraccionamientos/${formData.fraccionamiento}/lotes`, formData);

      if (response.status === 200) {
        // Manejar la respuesta exitosa
        console.log('Lote agregado exitosamente');
      } else {
        // Manejar error
        console.error('Error al agregar el lote');
      }
    } catch (error) {
      // Manejar error de la solicitud
      console.error('Error al enviar los datos del lote', error);
    }
  };

  return (
    <div className="max-w-md mx-auto mt-8 p-6 bg-white rounded-md shadow-md">
      <h3 className="text-2xl font-bold mb-4">Agregar Nuevo Lote</h3>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">Número de Lote:</label>
          <input
            type="text"
            name="numero_lote"
            value={formData.numero_lote}
            onChange={handleChange}
            className="mt-1 p-2 border rounded-md w-full"
          />
        </div>

        <div>
  <label className="block text-sm font-medium text-gray-700">Openings:</label>
  <div className="flex space-x-4">
    <div className="flex items-center">
      <input
        type="text"
        name="frente"
        placeholder="Frente"
        value={formData.openings.frente}
        onChange={handleOpeningsChange}
        className="mt-1 p-2 border rounded-md w-full"
      />
    </div>
    <div className="flex items-center">
      <input
        type="text"
        name="derecha"
        placeholder="Derecha"
        value={formData.openings.derecha}
        onChange={handleOpeningsChange}
        className="mt-1 p-2 border rounded-md w-full"
      />
    </div>
    <div className="flex items-center">
      <input
        type="text"
        name="izquierda"
        placeholder="Izquierda"
        value={formData.openings.izquierda}
        onChange={handleOpeningsChange}
        className="mt-1 p-2 border rounded-md w-full"
      />
    </div>
    <div className="flex items-center">
      <input
        type="text"
        name="atras"
        placeholder="Atrás"
        value={formData.openings.atras}
        onChange={handleOpeningsChange}
        className="mt-1 p-2 border rounded-md w-full"
      />
    </div>
  </div>
</div>

        <div>
  <label className="block text-sm font-medium text-gray-700">Vinil:</label>
  <input
    type="text"
    name="vinil"
    value={formData.vinil}
    onChange={handleChange}
    className="mt-1 p-2 border rounded-md w-full"
  />
</div>

<div>
  <label className="block text-sm font-medium text-gray-700">Basements:</label>
  <input
    type="text"
    name="basements"
    value={formData.basements}
    onChange={handleChange}
    className="mt-1 p-2 border rounded-md w-full"
  />
</div>

<div>
  <label className="block text-sm font-medium text-gray-700">Color:</label>
  <input
    type="text"
    name="color"
    value={formData.color}
    onChange={handleChange}
    className="mt-1 p-2 border rounded-md w-full"
  />
</div>

<div>
  <label className="block text-sm font-medium text-gray-700">Estado:</label>
  <input
    type="text"
    name="estado"
    value={formData.estado}
    onChange={handleChange}
    className="mt-1 p-2 border rounded-md w-full"
  />
</div>

        <button
          type="submit"
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Agregar Lote
        </button>
      </form>
    </div>
  );
};

export default AgregarLoteComponent;

